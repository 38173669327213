/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
/**  =====================
     Google Font
==========================  **/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600");
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../fonts/feather/css/feather.css");
@import url("../plugins/jquery-scrollbar/css/perfect-scrollbar.css");
@import url("../fonts/datta/datta-icon.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

/**  ====================================
      Custom css For this app by DM
=========================================  **/

/* .toast-success-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #4BB543 !important;
  border: 1px solid #34A853 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after{
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34A853;
} */

.vm-container{
  padding: 20px 0px;
  border: 2px solid lightgrey;
  border-radius: 10px;
  box-shadow: 4px 4px 11px 2px lightgrey;
}
.vm-container2{
  padding: 20px 0px;
  border: 2px solid lightgrey;
  border-radius: 10px;
  box-shadow: 4px 4px 11px 2px lightgrey;
  overflow-y:scroll;
  height: 500px;
}
.margin-top-40{
  margin-top: 40px;
}

.vm-component-container{
  margin-bottom: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.vm-component-container .custom-data-table-icon {
  font-size: 24px;
  margin: 0px 10px;
}
.vm-heading{
  text-align: center;
  
}
.vm-heading span{
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 10px;
  color: gray;
}

.cp-modal-check{
  width: 20px;
  height: 20px;
  vertical-align: sub;
}
.cp-modal-label{
  font-size: 18px;
  margin-left: 5px;
}

.modal-cross{
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}

.fwd-label{
  display: inline-block;
    padding: 2px 4px;
    font-size: 11.844px;
    font-weight: bold;
    line-height: 14px;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #999;
}

.ReactModalPortal i{
  cursor: pointer;
}

.rhap_progress-section{
  display: none;
}
.rhap_additional-controls{
  display: none;
}
.rhap_rewind-button{
  display: none;
}

.rhap_forward-button{
  display: none;
}

.rhap_volume-button{
  display: none;
}

.rhap_play-pause-button{
  display: none;
}

.rhap_container {
  float: left;
}
.voice-message-icons{
  border: 1px solid;
  border-radius: 55%;
  padding: 0px 1px 0px 1px;
}

.voice-messages-table{
  color: black;
}

.voice-messages-table th{
  border-top: none !important;
}

.voice-message-text span{

  margin-top: -1px;
  padding-top: 0px;
  height: 12;
  color: #999;
  word-wrap: break-word !important;
  border-left: 5px solid #e3e3e3;
  padding-left: 10px;
}
.voice-message-text{
  border: none !important;
}
.voice-messages-table table {
    table-layout:auto;
    width: 100%;
}
.voice-messages-table table td {
    white-space: normal;
    word-wrap: break-word;
}

.voice-message-layout{
  width: 100%;
  padding: 5px;
  margin-left: 0px;
}
.nonecreated {
  top: 50px;
  text-align: center;
  color: gray;
  position: relative;
}
.nonecreated h3{
  color: gray;
}

@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 667px)  {

    .voice-message-layout{
      font-size: 10px !important;
      padding: 8px;
    }

    .voice-message-layout .form-group option{
      font-size: 12px;
    }
    .voice-message-layout .form-group select{
      font-size: 12px;

    }
    /* .voice-message-layout .form-group{
        padding-left: 15px;
    } */

    .voice-messages-table td{
      padding: 5px 5px;
      widows: 10px;
      width: 4% !important;
    }
    .voice-messages-table th{
      padding: 0px 5px !important;
          width: 4% !important;
    }
    .rhap_play-pause-button svg {
        font-size: 15px;
    }
    .rhap_play-pause-button{
      height:  30px;
    }


}

.voice-mail-btn-div{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.voice-mail-row2{
  display: grid;
  grid-template-columns: auto auto;
}

.data-table-icon {
  font-size: 24px;
  margin: 0px 10px;
}
.spinner svg{
    position: fixed;
    z-index: 500;
}
.spinner h3{
    position: fixed;
    width: 100%;
    margin-top: 30px;
    z-index: 500;
}

.Spinning {
  animation: spin-animation 0.5s infinite;
  display: inline-block;
}
.m-b-10 {
  margin-bottom: 10px;
}

.customTitleMiddle{
  margin: 15px 0;
}

.customTitleMiddle > h3 {
  text-align: center;
}


/*Messages css*/

.message-container {
  display: grid;
  grid-template-columns: 80px auto 80px;
  grid-template-rows: auto auto;
  gap: 10px 10px;
  cursor: pointer;
  z-index: 100;

}

.message-image {
  grid-column: 1;
  grid-row: 1 / 3;
}

.MainIndexApp .message-image img{
  width: 60px;
}
.badge {
    width: 35px;
    position: relative;
    float: right;
    padding: 10px 0px;
    border-radius: 50%;
    background: #006699;
    color: white;
}
.message-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message-title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.MainIndexApp{
  margin: 40px 10px;
  font-family: 'Montserrat';
}

.message-notification{
  height: 30px;
}

.mesaage-summary{
  padding: 10px 10px;
  margin-right: -25px;
  margin-left: -25px;
}

.message-date span{
  float:right;
}



.message-send-container {
  display: grid;
  grid-template-columns: auto 40px;
  grid-template-rows: auto;
  gap: 10px 0px;
  position: fixed;
  bottom: 0px;
  width: 99%;
  z-index: 100;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 50px 0px 0px 50px;
  margin-left: -15px;
}

.message-send textarea{
  border-radius: 50px;
  overflow-y: hidden;
  resize: none;
  font-size: 16px;
  padding: 10px 20px;
  height: 44px;
}

.message-not-sent{
    background-color: pink;
    font-size: 12px;
}

.danger{
    color: red;
}

.danger i{
    margin-right: 6px;
}

.message-retry{
    position: absolute;
    color: red;
    font-size: 12px;
    right: 0;
}

.message-retry i{
    cursor: pointer;
}


.send-button span{
  background-color: #006699;
  width: 100%;
  padding: 12px 7px 10px 24px;
  margin-left: -16px;
  bottom: 0px;
  position: absolute;
  z-index: -1;
  height: 43px;
}
.send-button{
    cursor: pointer;
}
.send-button svg {

    font-size: 20px;
}

.MainIndexApp .message-card-his{
  margin-right: 20%;
  background-color: #e0e0eb;
  border-radius: 0px 8px 8px 8px;
  padding: 2px 10px;
  float: left;
}

.MainIndexApp .message-card-mine{
  margin-left: 20%;
  background-color: #b3ecff;
  border-radius: 8px 0px 8px 8px;
  padding: 2px 10px;
  float: right;
}

.MainIndexApp .message-card-mine .message-card-name{
  display: none;
}

.MainIndexApp .message-card-his .message-card-name{
      display: block;
}

.MainIndexApp .message-card-name{
    margin: 5px 16px 2px 16px;
    font-weight: 800;
    color: black;
}

.MainIndexApp .message-card-p{
  margin-bottom: 4px;
  margin-right: 25px;
  color: black;
  white-space: pre-wrap;
  font-size: 13px;
  /* width: 250px; */
  -ms-word-break: break-all;
     word-break: break-all;

     /* Non standard for WebKit */
     word-break: break-word;

-webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;

}
.message-card-span{
    font-size: 10px;
}

/* .MainIndexApp .message-card-mine span{
  font-size: 10px;
  float: right;
} */
.MainIndexApp .message-card-mine span {

    padding: 5px 16px 2px 16px;
}
/* .MainIndexApp .message-card-his span {

    font-size: 10px;
    float: right;

} */
.MainIndexApp .message-card-his span {

    padding: 9px 16px 2px 16px;
}

.message-send-container .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: none;
    outline: 0;
    box-shadow: none;
}
.dropdown-menu-custom {
  padding: 20px 0;
  margin-top: 15px;
  box-shadow: 0 1px 10px 0 rgba(69,90,100,.2);
  border: none;
}

.dropdown-menu-custom-preview {
  padding: 20px 0;
  margin-top: 15px;
  box-shadow: 0 1px 10px 0 rgba(69,90,100,.2);
  border: none;
  width: 16em;
  margin-left: 17px;
  padding: 5px;
}

.delete-message-icon-left{
  /* position: absolute;
  top: 0px;
  left: 0px; */
  position: absolute;
  top: -20px;
  left: -16px;
  width: 0px;
  /* background: #ff4f38;
  padding: 0px 15px 2px 9px !important;
  border-radius: 96px !important; */
}

.delete-message-icon-right{
  /* position: absolute;
  top: 0px;
  right: 0px; */
  /* float: right;
  cursor: pointer; */
  position: absolute;
  top: -16px;
  right: 0px;
  width: 0px;
  /* background: #ff4f38;
  padding: 0px 15px 2px 9px !important;
  border-radius: 96px !important;*/
} 

@media only screen
  and (min-device-width: 300px)
  and (max-device-width: 667px)  {
    .message-title h5{
      font-size: 16px !important;

    }
    .message-container {
      gap: 5px 5px;
      font-size: 14px;
  }
  
}
@media only screen
  and (min-device-width: 200px)
  and (max-device-width: 360px)  {
    .MainIndexApp .message-card-name{
      max-width: 5.8em;
      white-space: normal;
      word-break: break-all;
    }
}

.preview-message-container {
  display: grid;
  grid-template-columns: 80px auto;
  grid-template-rows: auto auto;
  gap: 5px 5px;
  cursor: pointer;
  z-index: 100;
  font-size: 14px;

}
.img-radius{
  border-radius: 999px;
}

.voice-mail-borderNone td{
  border: none;
}

.voice-mail-btn-td{
  padding-top: 1em !important;
}

.voice-mail-displayInfo span{
  font-weight: 700;
  color: black;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

input[type="checkbox" i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}

.vmSettingsPage{
  margin-bottom: 80px;
}

.vmSettingsPage label{
  font-size: 16px !important;
}

.vmEnable{
  text-align: center;
  display: block;
}

.vmSettingsPage .custom-control {
  padding-left: 0;
  
}

.vmSettingsPage .link-btns {
  padding-left: 0;
  padding-right: 0;
}

.vol-span{
  padding: 6px;
  border: 2px solid lightgrey;
  border-radius: 999px;
  font-size: 16px;
}

.floating-footer{
  position: fixed;
    bottom: 0px;
    left: 0;
    padding: 10px;
    text-align: center;
    width: 98%;
    background: white;
}

.floating-footer button{
  margin-left: 10px;
  width: 100px;
}

.new-button{
  font-size: 18px;
  padding: 2px 8px;
  border-radius: 999px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: #006699;
  color: white;
  position: fixed;
  bottom: 65px;
  right: 60px;
  z-index: 1000;
  font-size: 24px;
}

.bg-full{
  width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    /* align-content: center; */
    justify-content: center;
    height: 100vh;
    
}

.auth-wrapper{
  position: relative;
  display: -webkit-flex;
  display: flex;
  /* -webkit-align-items: center; */
  /* align-items: center; */
  -webkit-justify-content: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.setting-image img{
  width: 60px;
  cursor: pointer;
}
.setting-image h5{
  color: white;
    font-size: 16px;
    margin-top: 10px;
    font-family: 'Montserrat';
    font-weight: 600;
}

.setting-image{
  text-align: center;
  text-align: -webkit-center;
}

.img-icons{
  width: 24px;
  margin: 0px 10px;
}

.theme-bg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.auth-wrapper .auth-content {
  position: relative;
  width: 390px;
  padding: 15px;
  z-index: 5;
}

.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
}

.signUpBackgroundImage {
  background: linear-gradient(90deg,#16222a,#3a6073);
}

.custom-sigin-logo {
  padding: 30px 50px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  border: none;
  margin-bottom: 30px;
  transition: all .5s ease-in-out;
}

.qrCustomBtn .fa-spin{
  margin-right: 0px !important;
  margin-top: 13px !important;
}

.greeting-play-pause svg{
  margin-top: 6px;
}

.call-parking-layout{
  font-size: 10px !important;
  padding: 20px;
  overflow: hidden;
}

.user-initials{
  text-align: center;
  padding: 10px;
}
.user-initials a {
  
  text-align: center;
}

.user-initials a label{
  padding: 23px 13px 20px;
  border-radius: 55px;
  background-color: MediumSeaGreen;
  font-size: 24px;
  font-weight: 600;
  color: white;
  width: 80px;
  text-align: center;
  margin-bottom: 0px;
}
.user-description{
  text-align: center;
}

.user-description p {
  font-size: 14px;
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 4px;
  padding: 0px 2px;
}

.parking-container{
  padding: 10px;
}