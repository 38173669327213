.forgot-p{
    text-align: center;
    margin-top: 20px;
}
.reset-p{
    margin-top: 20px;
}
.go-back{
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 600;
}
.qr-div {
    text-align: center;
    margin-top: 20px;
    font-size: 50px;
}