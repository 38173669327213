
.template-add-btn{
    float: right;
    font-size: 20px;
    cursor: pointer;
}

.template-save-btn{
    float: right;
}

.qr-editor{
    margin-top: 5px;
}

.cke_focus{
    padding: 0 !important;
}
.templates{
    margin-top: 10px;
    .template-container{
        padding: 7px 8px 0px 20px;
        border: 1px solid black;
        text-align: center;
        border-radius: 100px;
        font-size: 16px;
        width: fit-content;
        margin: 8px;

        label{
            margin-right: 12px;
            cursor: pointer;
        }
        div{
            font-size: 20px;
            margin-top: -2px;
            float: right;
            cursor: pointer;
        }
    }
}

.modal-dialog{
    max-width: 800px;
    width: 100%;
    margin: 1.75rem auto;
}
.callIdModal{
    .flex-container {
        display: flex;
        gap: 10px;
    }
    
    
    .item-1 {
        flex: 0 0 80%; /* flex-grow: 0, flex-shrink: 0, flex-basis: 80% */
    }
    
    .item-2 {
        flex: 0 0 20%; /* flex-grow: 0, flex-shrink: 0, flex-basis: 20% */
    }
    .mdb-dataTable{
        //hide first column
        thead tr th:nth-child(1)
        {
            display: none;
        }
        tbody tr td:nth-child(1)
        {
            display: none;
        }
    }
    
}
